import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'lape'
import format from 'date-fns/format'
import set from 'lodash/set'
import get from 'lodash/get'

import { Box, Portal, VStack } from '@revolut/ui-kit'
import Form from '@src/features/Form/Form'
import { getStartSurveyFormRequests } from '@src/api/engagement'
import LapeNewDatePicker from '@components/Inputs/LapeFields/LapeNewDatePicker'
import LapeNewTimeInput from '@components/Inputs/LapeFields/LapeNewTimeInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EngagementStartSurveyInterface } from '@src/interfaces/engagement'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { useGetSelectors } from '@src/api/selectors'

enum FormTabs {
  SendNow = 'Send now',
  ScheduleLater = 'Schedule for later',
}

const tabs = [FormTabs.SendNow, FormTabs.ScheduleLater]

const StartSurveyFormContent = ({
  isOpen,
  onClose,
  onReject,
  refetch,
  cancelButtonText = 'Cancel',
}: Omit<Props, 'surveyId'>) => {
  const { submit, values } = useLapeContext<EngagementStartSurveyInterface>()

  const [isStartSubmitPending, setIsStartSubmitPending] = useState(false)

  useEffect(() => {
    const currentTime = `${format(new Date(), 'yyyy-MM-dd')}T${format(
      new Date(),
      'hh:mm:ss',
    )}`

    set(values, 'send_on_date', currentTime)
    values.send_on_time = currentTime
  }, [])

  const sendOnDate = get(values, 'send_on_date')

  useEffect(() => {
    if (!sendOnDate) {
      return
    }
    const [newDate] = sendOnDate.split('T')
    const [, time] = get(values, 'send_on_time').split('T')

    values.send_on_time = `${newDate}T${time}`
  }, [sendOnDate])

  const { data: timezones = [], isLoading: isTimezonesLoading } = useGetSelectors<{
    id?: string
    name?: string
    offset?: string
  }>(selectorKeys.timezones)

  const currentTimezone = useMemo(() => {
    return timezones.find(t => t.id === Intl.DateTimeFormat().resolvedOptions().timeZone)
  }, [timezones])

  useEffect(() => {
    if (currentTimezone) {
      values.send_on_timezone = currentTimezone
    }
  }, [timezones])

  const { tabBar, currentTab } = useTabBarSwitcher({
    tabs,
    highlightSelected: false,
  })

  const isSendNowTab = currentTab === FormTabs.SendNow

  return (
    <ConfirmationDialog
      preventUserClose
      open={isOpen}
      onClose={onClose}
      onConfirm={async () => {
        setIsStartSubmitPending(true)
        try {
          set(values, 'send_on_date', undefined)

          await submit()
        } finally {
          setIsStartSubmitPending(false)
          onClose()
          refetch && refetch()
        }
      }}
      loading={isStartSubmitPending}
      onReject={onReject || onClose}
      label="Please specify when you want to start the survey"
      body={
        <VStack space="s-16">
          <Box>{tabBar}</Box>
          <LapeNewDatePicker disabled={isSendNowTab} name="send_on_date" label="Date" />
          <LapeNewTimeInput
            required
            disabled={isSendNowTab}
            name="send_on_time"
            label="Time"
          />
          <LapeRadioSelectInput
            disabled={isTimezonesLoading || isSendNowTab}
            name="send_on_timezone"
            selector={selectorKeys.timezones}
            label="Timezone"
          />
        </VStack>
      }
      yesMessage="Confirm"
      noMessage={cancelButtonText}
    />
  )
}

type Props = {
  surveyId: number
  isOpen: boolean
  onClose: () => void
  refetch?: () => void
  onReject?: () => void
  cancelButtonText?: string
}
export const StartSurveyForm = connect((props: Props) => (
  <Portal>
    <Form
      forceParams={{ id: undefined }}
      api={getStartSurveyFormRequests(props.surveyId)}
    >
      <StartSurveyFormContent {...props} />
    </Form>
  </Portal>
))
